import Vue from 'vue'
import Vuex from 'vuex'
import UserModule from '@/store/user'
import MenuModule from '@/store/menu'
import AnimalList from '@/store/animal'
import Hospital from '@/store/hospital'
import Errors from '@/store/errors'
import Post from '@/store/post'
import Services from '@/store/services'
//import Organisation from '@/store/organisation'

Vue.use(Vuex)

//import Axios from 'axios'


export default new Vuex.Store({
  modules: {
    UserModule,
    MenuModule,
    AnimalList,
    Hospital,
    Errors,
    Post,
    Services,
    //Organisation,
  }
})
