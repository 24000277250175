import http from '../store/http'

export default {
	state: {
		articles: [],
		post: '',
		articles_loading: true,
		post_loading: true,
		stocks: [],
		about: '',
		post_list: [],
		post_list_loading: false,
		post_info: '',
		post_info_loading: false,
	},
	getters: {
		getArticles(state) { return state.articles },
		getArticlesLoading(state) { return state.articles_loading },
		getPost(state) {return state.post },
		getPostLoading(state) { return state.post_loading },
		getStocks(state) { return state.stocks },
		getAbout(state) { return state.about },
		getPostList(state) { return state.post_list},
		getPostListLoading(state) { return state.post_list_loading},
		getPostInfo(state) { return state.post_info },
		getPostInfoLoading(state) { return state.post_info_loading },
	},
	mutations: {
		uloadPostInfo(state, params) {
			// Загружаем информацию по документу по его 
			var URL = params.url+"?id="+params.id
			console.log("uloadPostInfo->URL:", URL)
			state.post_info_loading = true
			http.get(URL)
			.then(response=>{
				state.post_info = response.data
				console.log('uloadPostInfo->response.data:', response.data)
				state.post_info_loading = false
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload Post Info: ", err.message));console.error(err)})
			console.log('uloadPostInfo->END')
		},
		uploadPostList(state, url){
			//var url = '/site/documents'
			console.log('uploadPostList->url:', url)
			state.post_list_loading = true
			http.get(url)
			.then(response=>{
				state.post_list = response.data
				state.post_list_loading = false
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload Post List: ", err.message));console.error(err)})
		},
		uploadArticles(state) {
			var URL = '/site/post'
			console.log('uploadArticles->url', URL)
			http.get(URL)
			.then(response=>{
				//console.log(response)
				state.articles = response.data
				state.articles_loading = false
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload Articles: ", err.message));console.error(err)})
		},
		uploadPost(state, id){
			var URL = '/site/post?id='+id
			http.get(URL)
			.then(response=>{
				//console.log(response)
				state.post = response.data
				state.post_loading = false
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload Post (ID:", id, "): ", err.message));console.error(err)})
		},
		uploadStocks(state) {
			var URL='/site/post?stock=1'
			http.get(URL)
			.then(res=>{
				state.stocks = res.data
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload stocks: ", err.message));console.error(err)})

		},
		uploadAbout(state) {
			var URL = '/site/post?slug=about'
			http.get(URL)
			.then(response=>{
				state.about = response.data[0].content
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload About: ", err.message));console.error(err)})
		}
	},
	actions: {
		uploadArticlesAsync(context) { context.commit('uploadArticles') },
		uploadPostAsync(context, id) { context.commit('uploadPost', id )},
		uploadStocksAsync(context)   { context.commit('uploadStocks') },
		uploadAboutAsync(context)    { context.commit('uploadAbout') },
		uploadPostListAsync(context, url) { context.commit('uploadPostList', url)},
		uloadPostInfoAsync(context, {url, id}) { context.commit('uloadPostInfo', {url:url, id:id})},
	},
}