//import Axios from 'axios'
//const DOMAIN = 'http://lk.vetlight.ru:65500'
import http from '../store/http' 



export default {
	state: {
		animal_list:[
			{name:'asdf'},
		],
		count: 0,
		animals: [
			{name:'asdfsa', animal_pet_full_name:'asdfs', animal_id:1},
		],
		animal_loading: true,
	},
	getters: {
		getAnimalList: (state) => {
			//console.log('getAnimlList')
			return state.animals
		},
		getAnimalsID: (state) => (id) => {
			console.log("getAnimalID" + id, state)
			return null
		},
		getAnimalLoading: (state) => {
			return state.animal_loading
		}
	},
	mutations: {
		increment(state, payload){
			state.count+= payload.amount;
		},
		setAnimalList(state, payload){
			//console.log("setAnimals")
			//console.log(payload)
			state.animal_list = payload.title
		},

		getAnimals(state, payloads) {
			//var URL = DOMAIN + '/api/v1/clients/animal/'
			var URL = '/clients/animal/'
			//console.log('getAnimals: ' + URL)
			/console.log("getAnimals", payloads)
			http.get(URL)
			.then(res=>{
				//console.log(res.data)
				state.animals = res.data
				state.animal_loading = false
			})
			.catch(err=>{this.commit('addErrors', "".concat("Animal: ", err.message));console.error(err)})
		},
		
	},
	actions: {
		getAnimalsAsync(context) {
			//console.log("actions")
			//console.log(context)
			context.commit('getAnimals')
		}
	},
}

