import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

//import VueTelInputVuetify from "vue-tel-input-vuetify";

import LayoutDefault from './layouts/Default';
import LayoutAdmin from './layouts/Admin';
import LayoutClear from './layouts/LayoutClear.vue';


// ---- Отключаем логи -----
console.log = function() {}
// -------------------------

Vue.config.productionTip = false

// Получаем токен из локального хранилища, если он есть
const token = localStorage.getItem('token')

// Подключаем Axios что бы он был всегда доступен
//import Axios from 'axios'
import http from './store/http'
Vue.prototype.$http = http


if (token) {
  // {"id":1,"username":"chistov","expires_in":"020320221628",
  // "ua":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.102 Safari/537.36",
  // "ip":"31.40.100.125",
  // "roles":["org_edit","staff_management","accountant_management","warehouse_management","veterinar",
  //   "administrator","assistant","site_management"]}
	// Устанавливаем токен в заголовок при работе с axios
  console.log('main.js: Устанавливаем токен', token)
  // Проверяем expires_in и если он закончился - делаем запрос refresh token или удаляем
	Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token
} else {
  console.log('main.js: Токен не получен')
}


//Vue.use(VueTelInputVuetify)

/*
http.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log("OK FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFff")
    return config;
  }, function (error) {
    // Do something with request error
    console.log("ERROR FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFff")
    return Promise.reject(error);
  });
*/

http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("main.js  OK. Return respons=", response)
    console.log("main.js.interceptors  OK. Return response.config.url", response.config.url)
    console.log("main.js.interceptors  OK. Return response", response)
    return response;
  }, async function (err) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('main.js.interceptors error', err.response)
    //console.log('main.js.interceptors run refreshTokenAsync', err.response.status)
    //store.dispatch('refreshTokenAsync')
    // сохраняем конфиг
    const originalConfig = err.config;
    // /lk/auth/refreshtoken
    // /api/v1/lk/login
    // Если это не страница личного кабинета и есть ошибка
    //console.log('err.response:', err.response)
    if (originalConfig.url !== "/lk/login" && err.response) {
      // Пользователь ранее авторизовался, но сервер его не пускает, возможно истек access_token
      //console.log('main.js.interceptors not URL and error:', err)
      //console.log('main.js.interceptors not URL and error.response:', err.response)
      //console.log('main.js.interceptors originalConfig._retry:', originalConfig._retry)
      console.log('main.js.interceptors err.response.status === 401 && !originalConfig._retry:', (err.response.status === 401 && !originalConfig._retry))
      //console.log('main.js.interceptors err.response.status === 401:', (err.response.status === 401))
      //console.log('main.js.interceptors !originalConfig._retry:', (!originalConfig._retry))
      if (err.response.status === 401 && !originalConfig._retry) {
        // Если это первый запрос - ставим тег, что бы не повторять и пробуем обновить токен
        originalConfig._retry = true;   // Ставим флаг что мы запрашиваем обновление. Если флаг установлен, данное условие больше не выполнится
        console.log('main.js.interceptors: originalConfig._retry = true')
        console.log('main.js.interceptors: вызываем обновление refreshTokenAsync', originalConfig)
        // Первый раз не обновляем refreshToken, т.к. функция работает асинхронно и результат будет возвращен после следующего запроса
        store.dispatch('refreshTokenAsync')
        // Возвращаем конфиг 
        console.log('originalConfig:', originalConfig)
        //return Promise.reject(err);
        return http(originalConfig);
      } else {
        // Если это повторный запрос - обновлять токен не нужно
        //console.log('main.js.interceptors: ERRORR AUTH. Ошибка авторизации. Пробуем обновить токет. Run refreshTokenAsync')
        console.log('main.js.interceptors: ERRORR AUTH. Это вторая попытке. Значит проблема с авторизацией. Возвращаем ошибку')
        // Если ошибка авторизации пробуем обновить токен
        //store.dispatch('refreshTokenAsync')
        //console.log(originalConfig.url)
        //originalConfig.url = '/'
        //return http(originalConfig);
        //
        // Выходим
        //store.dispatch('logout')
        //router.push('/')
        return Promise.reject(err);
      }
    } else {
      console.log('main.js.interceptors: Return ERROR (Promise.reject)')
      return Promise.reject(err);
    }
    
    
  });


Vue.component('default-layout', LayoutDefault)
Vue.component('admin-layout',   LayoutAdmin)
Vue.component('clear-layout',   LayoutClear)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')



