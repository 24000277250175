<template>
	<div>
		<v-expansion-panels focusable v-model="panel" hover>
			<v-expansion-panel v-for="(doctor, i) in doctors" :key="i" @change='makeUrl(doctor)'>
				<v-expansion-panel-header>
					<v-row>
						<v-col class="pa-0 text-uppercase text-center col-12 light-blue--text text--darken-2"><h3>{{ doctor.name }}</h3></v-col>
					</v-row>
				</v-expansion-panel-header>
				<v-expansion-panel-content class='pa-0 ma-0'>
					<v-row class="ma-0">
						<v-col class="col-12 col-sm-5 col-md-3">
							<v-img :src="doctor.photo" :alt="doctor.name" min-height="350" min-width="140" class='bordered'></v-img>
						</v-col>
						<v-col class="col-12 col-sm-7 col-md-9">
							<v-row>
								<v-col class="d-none d-sm-block text-uppercase text-center col-12 light-blue--text text--darken-2"><h3>{{ doctor.name }}</h3></v-col>
								<v-col class="text-center col-12 light-blue--text text--darken-2">{{ doctor.titul }}</v-col>
								<v-col class="col-12"><hr></v-col>
								<v-col class="col-12"><span v-html="doctor.site_info"></span></v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

	</div>

</template>

<script>

export default {
	name: 'Doctors',
	data: () => ({ 
		_panel:null,
	}),
	props: [ 'doctor_id',],
	components: {},
	methods: {
		makeUrl(val) {
			console.log('makeUrl', val)
			this.$router.push('/staff/'+val['id'])
		}
	},
	computed: {
		doctors() { return this.$store.getters.getDoctors },
		panel: {
			// Если указали ID сотрудника - будем его сразу раскрывать
			get: function() {
				//console.log('panel get', this.doctor_id)
				if (this.doctor_id){
					for (var i in this.doctors) {
						if (this.doctors[i]['id'] == this.doctor_id){
							//this._panel = Number(i)
							//console.log("FIND DOCTOR ID:", i, "(", typeof i, ")", "this._panel", this._panel)
							if (typeof i == 'string'){
								//console.log('string', i, "->", Number(i))
								//this._panel = Number(i)
								return Number(i)
							}
							//this._panel = Number(i)
							return this._panel
						}
					}
				} else { 
					return this._panel 
				}
				return null
			},
			set: function(newValue) {
				//console.log('panel set', newValue)
				this._panel = Number(newValue)
			},
		}
	},
	beforeMount() {
		this.$store.dispatch('getDoctorsAsync')
	}
}
</script>

<style>
.v-expansion-panel-content__wrap {
	padding: 0px;
}
</style>