<template>
	<v-col class="col-12">
		<a name='contacts'></a>
		<v-card color='grey lighten-5'>
			<v-card-title class="text-center light-blue--text text--darken-2 text-h4">КОНТАКТЫ</v-card-title>
			<v-tabs v-model="tab" background-color="transparent" color="basil" grow class="d-none d-sm-block">
				<v-tab v-for="item in contacts" :key="item.org_name_short">{{ item.org_name_short }}</v-tab>
			</v-tabs>
			<v-tabs v-model="tab" background-color="transparent" color="basil" grow class="d-block d-sm-none">
				<v-tab v-for="item in contacts" :key="item.org_nname_exshort">{{ item.org_nname_exshort }}</v-tab>
			</v-tabs>
			
			<v-tabs-items v-model="tab">
				<v-tab-item v-for="item in contacts" :key="item.title">
					<v-card color="basil" flat class="text-caption">
						<v-card-text class='pb-0'><v-btn :href="'tel:'+item.org_phone" text small ><v-icon>mdi-phone-outline</v-icon> {{ item.org_phone }}</v-btn></v-card-text>
						<v-card-text class='py-0 pr-0'><v-btn :href="'mailto:'+item.org_email" text small><v-icon>mdi-email-outline</v-icon> {{ item.org_email }}</v-btn></v-card-text>
						<v-card-text class='py-0'><v-btn text small><v-icon>mdi-clock-outline</v-icon> {{ item.org_business_hours_start }} - {{ item.org_business_hours_stop }}</v-btn></v-card-text>
						<v-card-text class='py-0'><v-btn text small href="https://www.instagram.com/kondorvet_clinic/"><v-icon>mdi-instagram</v-icon>@kondorvet_clinic</v-btn></v-card-text>
						<v-card-text class='py-0'><v-btn text small href="https://vk.com/kondorvet"><v-icon dark>mdi-vk</v-icon>kondorvet</v-btn></v-card-text>
						<v-card-text>Адрес: {{ item.org_address }}</v-card-text>
						<div style="height:300px">
							<yandex-map :coords="item.org_coordinates" :zoom="15">
								<ymap-marker :coords="item.org_coordinates"  marker-id="123" hint-content="some hint"></ymap-marker>
							</yandex-map>
						</div>						
						<!--<div style="position:relative;overflow:hidden;"><iframe :src="item.yalink" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>-->
					</v-card>
				</v-tab-item>
			</v-tabs-items>

		</v-card>
	</v-col>
</template>



<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
	name: 'Contacts',
	components: { yandexMap, ymapMarker },
	data () {
		return {
			tab: null,
		}
	},
	computed: {
		contacts() { return this.$store.getters.getContacts }
	},
	beforeMount(){
		this.$store.dispatch('uploadContactsAsync')
	}
}
</script>


<style scoped>

.ymap-container {
  height: 100%;
}
</style>