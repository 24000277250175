<template>
	<v-card>
		<v-app-bar color="light-blue darken-2" dark fixed class="d-print-none">
			<router-link to="/"><v-avatar color="white" size="50" class="mr-2"><img src="/img/kondor_logo_mini.png" alt="KondorVet logo"></v-avatar></router-link>
			<v-app-bar-nav-icon class="d-block d-md-none" dark @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title class="d-none d-lg-block text-h5">ВК "Кондор"</v-toolbar-title>
			<v-spacer></v-spacer>
			<div class="d-none d-md-block">
				<template v-for="(menu, index) in menus">
					<template v-if="!menu.child"><v-btn text :to="menu.route" :key="index">{{ menu.title}}</v-btn></template>
					<template v-else>
						<v-menu offset-y :key="index">
							<template v-slot:activator="{on, attrs}">
								<v-btn v-bind="attrs" v-on="on" text dark>{{ menu.title }}</v-btn> <v-icon role='img'>fas fa-list</v-icon>
							</template>
							<v-list>
								<v-list-item v-for="(child, index_child) in menu.child" :key="index_child" :to="child.route">{{ child.title }}</v-list-item>
							</v-list>
						</v-menu>
					</template>
				</template>
			</div>
			<v-spacer></v-spacer>
			<v-toolbar-title class="d-block text-body-2" >
				<v-col class="col-12 ml-0 pa-0">
					<v-btn text class="text-button" href="tel:+74997130777"><v-icon>mdi-phone</v-icon>+7 (499) 713-07-77</v-btn>
				</v-col>
			</v-toolbar-title>
		</v-app-bar>

		<!-- <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" > -->
			<v-main class="pt-14">
				<v-container>
					<router-view name="staff"></router-view>
					<router-view></router-view>
				</v-container>
			</v-main>
		<!-- </v-sheet> -->

		<v-navigation-drawer app temporaty v-model='drawer' class="d-md-none" >
			<v-list class="d-md-none">
				<template v-for="(menu, index) in menus">
					<template v-if="menu.child">
						<v-list-group no-action sub-group :value="true"  :key="index">
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>{{ menu.title }}</v-list-item-title>
								</v-list-item-content>
							</template>
							<v-list-item v-for="(child, index_child) in menu.child" :to="child.route" :key="index_child"> {{ child.title }}</v-list-item>
						</v-list-group>
					</template>
					<template v-else>
						<v-list-item :to="menu.route" :key="index">
							<v-list-item-title>{{ menu.title }}</v-list-item-title>
						</v-list-item>
					</template>
				</template>
			</v-list>
		</v-navigation-drawer>




	</v-card>

</template>

<script type="text/javascript">


export default {
	name: 'DefaultLayout',
	components: {
	},
	data () {
		return {
			drawer: false,
			w_height: 1500,
		}
	},
	computed:{
		menus() {
			return [
				{title: 'Главная', route:'/'},
				//{title: 'Запись на прием', route:'/appointment'},
				{title: "Кабинет", route:'/llk/'},
				{title: 'Контакты', route:'/contacts'},
				{title: "Меню", child:[
					//{title: 'Услуги и цены', route:'/price'},
					{title: 'Наши врачи', route:'/staff'},
					{title: 'Документы', route:'/documents'},
					{title: 'Статьи', route:'/articles'},
					{title: 'Рекомендации', route:'/recommendation'},
					{title: 'Акции', route:'/stock'},
					{title: 'Новости', route:'/news'},
					{title: 'О проекте', route:'/about'},
					{title: 'Врачам', route:'/doctors'},
				]}
			]
		}
	},
	mounted() {
		// Изменяем значение велечины экрана для адаптивного интерфейса
		this.w_height = document.documentElement.clientHeight
		this.$nextTick(function(){
			var vm = this
			window.addEventListener("resize", function() {
				//console.log(`${vm.w_height} -> ${document.documentElement.clientHeight}`)
				vm.w_height = document.documentElement.clientHeight
				//console.log(`set: ${vm.w_height}`)
				//console.log('--------')
			}, false);

		})
	},
}
</script>


<style>




</style>
