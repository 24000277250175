<template>
	<v-col>
		<v-row justify="center" class="pa-2">
			<v-col class='col-12  ma-0 pa-0 text-center text-h6 text-uppercase text-sm-h4 text-md-h4' style="cursor: default">Ветеринарная клиника</v-col>
			<v-col class='col-12  ma-0 pa-0 text-center text-h3  text-sm-h3 text-md-h3 font-weight-bold' style="cursor: default">Кондор</v-col>
			<v-col class="text-center col-12 pa-0 text-caption text-sm-h5" style="cursor:default"><b>здоровый</b> питомец - <b>счастливый</b> владелец.</v-col>
			<v-col class="d-none d-sm-block col-12" style="cursor:default">
				Наша клиника и наши специалисты работают на базе доказательной медицины. Ежегодно проходят  аттестацию и повышение квалификации. Наша клиника оборудована рентгеном, УЗ аппаратом, ЭКГ, собственной лабораторией, что позволяет проводить диагностику уже на приеме, получать быстрый результат, давать рекомендации и назначать правильное лечение. Круглосуточный стационар с камерами оксигенации в ВК Кондор позволяет специалистам принимать экстренных животных. Оборудованный отдельный стерильный хирургический кабинет позволяет проводить хирургические манипуляции различной сложности. Современная стоматологическая установка даёт возможность пациентам проходить ортодонтологическое лечение.
			</v-col>
		</v-row>
	</v-col>
</template>

<script type="text/javascript">

export default {
	name: 'USP',
	data () {return {}},
  methods: {},
  beforeMount(){}
}	
</script>

<style type="text/css">
	
</style>
