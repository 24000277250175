<template>
  <v-app>
    <component :is="layout">
    </component>
  </v-app>
</template>

<script>

//import HeaderAdmin from '@/components/HeaderAdmin'
//import Header from '@/components/Header'

export default {
  name: 'App',
  data: () => ({ 
    drawer: null, 
  }),
  
  components: {
    //HeaderAdmin,
    //Header,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  }
};
</script>
