import http from '../store/http' 

export default {
	state: {
		perpage: 40,
		services: [],
		price: [],
		price_load: true,
		upload_appointment_message: '',
		upload_appointment_success:false,
		upload_appointment_load: true,
		zreports: [],
		animalresearch: {},
		appointments_application:false,
	},
	getters: {
		getServiceList(state)			{ return state.services },
		getPrice(state) 				{ return state.price },
		getPriceLoad(state) 			{ return state.price_load },
		getAppointmentMessage(state) 	{ return state.upload_appointment_message },
		getAppointmentStatus(state) 	{ return state.upload_appointment_success },
		getAppointmentLoad(state) 		{ return state.upload_appointment_load },
		getZReports(state)				{ return state.zreports },
		getAnimalResearch(state)		{ return state.animalresearch },
		getClientAppointmentsApplication(state){ return state.appointments_application }, 
	},
	mutations: {
		uploadClientAppointmentsApplication(state, data){
			// http://lk.kondorvet.ru:65500/api/v1/clients/appointments_application
			var URL = '/clients/appointments_application'
			console.log("GET ", URL, data)
		},
		uploadAnimalResearch(state, data){
			// загружаем исследование по уникальному ID (/api/v1/medcard/animalresearch)
			console.log('store->services->uploadAnimalResearch data:', data)
			var URL = '/medcard/animalresearch?'+data.db+'='+data.id
			http.get(URL)
			.then(response=>{
				var obj = response.data.result
				console.log(obj)
				state.animalresearch = obj
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload Animal Research: ", err.message));console.error(err); state.animalresearch = null})
		},
		uploadZReports(state) {
			// Получаем Z-отчеты
			// /api/v1/accounting/zreport?org=1&start=10.01.2021
			var URL = '/accounting/zreport?start=01.01.2021'
			http.get(URL)
			.then(response=>{
				//console.log("uploadZReports:", response)
				state.zreports = response.data
			})
		},
		uploadServiceList(state) {
			var URL = '/site/servicelist'
			http.get(URL)
			.then(response=>{
				state.services = response.data
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload Services List: ", err.message));console.error(err)})
		},
		uploadPrice(state) {
			var URL = '/finance/services/?price=services&fields=name&fields=id&fields=price&fields=category&nocategory&perpage='+state.perpage
			console.log('upload')
			http.get(URL)
			.then(response=>{
				state.price = response.data
				state.price_load = false
			})
		},
		setAppointmentMessage(state,value){ state.upload_appointment_message = value},
		uploadAppointment(state, data){
			// Готовим данные для отправки на сервер
			// {'org': '1', 'staff': '1', 'appointment_date_day': '04.02.2022', 
			// 'appointment_date_time': '17:30', 'phone': '+7 (000) 000-00-00d', 'animal': '', 
			// 'reason_for_petition': 'sdfa', 'feedback': '', 'found_out_about_us': ''}
			console.log('store->services->uploadAppointment: data:', data)
			state.upload_appointment_load = true
			data.staff = data.staff.staff_id 			// Не трогаем запись, т.к. передаем сотрудника
			data.appointment_date_day = new Date(data.date).toJSON()
			data.appointment_date_time = data.time
			data.reason_for_petition = data.reasonForPerson 		// Причина обращения
			data.found_out_about_us  = ''							// От куда узнали о нас
			data.animal = data.animalType 							// Вид животного
			console.log('store->services->uploadAppointment->animalType:', data)
			var rs = http.post('/clients/appointments/', data)
			.then(res=>{
				console.log("store->services->uploadAppointment return res:", res)
				state.upload_appointment_message = 'Запусь успешно создана'
				state.upload_appointment_success = true
				state.upload_appointment_load = false
			//	this.commit('setToken', {token:res.data.access_token, user:'chistov', refreshToken:res.data.refresh_token})
			})
			.catch(err=>{ 
				state.upload_appointment_message = 'Ошибка создания записи. Повторите процедуру записи заново. Возможно выбранное время уже занято'
				state.upload_appointment_success = false
				state.upload_appointment_load = false
				console.log("store->services->uploadAppointment return error:", err)
			})
			console.log('store->services->uploadAppointment: rs', rs)
			console.log(state.upload_appointment_message)
		},
		sendClientAppointmentsApplication(state, data){
			var URL = '/clients/appointments_application'
			http.post(URL, data)
			.then(res=>{
				console.log("store->services->sendClientAppointmentsApplication return res:", res)
				state.appointments_application = true // Устанавливаем тег, что запись создана
			})
			.catch(()=>{
				console.error("store->services->sendClientAppointmentsApplication ERROR: Ошибка сервера или связи с сервером")
			})
		},
	},
	actions:{
		uploadServiceListAsync(context) { context.commit('uploadServiceList') },
		uploadPriceAsync(context) { context.commit('uploadPrice') },
		uploadZReportsAsync(context) { context.commit('uploadZReports') },
		uploadAnimalResearchAsync(context, data) { context.commit('uploadAnimalResearch', data)},
		sendClientAppointmentsApplication(context, data){ context.commit('sendClientAppointmentsApplication', data) },
	},

	
}