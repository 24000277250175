<template>
	<div>
		<QuestionVetAnamnes></QuestionVetAnamnes>
	</div>
	
</template>

<script type="text/javascript">
import QuestionVetAnamnes from '@/components/QuestionVetAnamnes'
export default {
	name:'Anamnesis',
	components: {
		QuestionVetAnamnes,
	}

}
</script>