<template>
  <v-row>
    <v-col class="col-12 d-none d-sm-block ">
      <v-carousel cycle height="312" show-arrows-on-hover hide-delimiter-background hide-delimiters>
        <v-carousel-item v-for="(stock, i) in stocks" :key="i" :src="stock.bg" aspect-ratio="1" contain link :to="{path:'/post/'+stock.id}">
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col class="col-12 d-sm-none">
      <v-carousel cycle height="220" show-arrows-on-hover hide-delimiter-background hide-delimiters>
        <v-carousel-item v-for="(stock, i) in stocks" :key="i" :src="stock.bg" aspect-ratio="1" contain link :to="{path:'/post/'+stock.id}">
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>


<script type="text/javascript">

export default {
	name: 'Stocks',
  computed: {
    stocks() { return this.$store.getters.getStocks },
  },
  beforeMount(){
    this.$store.dispatch('uploadStocksAsync')
  }
}
</script>
