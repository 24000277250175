export default {
	state: {
		errors: [],
	},
	getters: {
		getErrors(state) { return state.errors }
	},
	mutations: {
		addErrors(state, message) { state.errors.push(message)},
		clearErrors(state) { return state.errors = [] }
	},
}
