<template>
	<div>
		HospitalCell {{ $route.params.id }}
	</div>
	
</template>

<script type="text/javascript">

export default {
	name: 'HospitalCell',
	beforeMount () {
		//console.log("FFF:"+this.$route.params.id)
		this.$store.dispatch('getHospitalRecordsAsync', this.$route.params.id)
	},
}

</script>