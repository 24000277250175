import http from '../store/http';


export default {
	state: {
		user:'',
		status: '',
		token: localStorage.getItem('token'),
		refreshToken:'',
		doctors: [],
		doctors_panel: null,
		staff_list: [],
		staff_list_loading: true,
		contacts:[],
		orgs: [],
		appointment_org_info: [],
		schedule: [],
		schedule_time: [],
		org_info: {},
	},
	getters: {
		getToken(state) {return state.token	},
		getStaffList(state) { return state.staff_list },
		getStaffList_loading(state) { return state.staff_list_loading },
		isLoggedIn: state => !!state.token,
		//isLoggedIn(state) {	console.log(state.token); console.log(!!state.token); return !!state.token },
		authStatus: state => state.status,
		getDoctors(state) { return state.doctors },
		getDoctorsPanel(state) { return state.doctors_panel },
		getContacts(state) { return state.contacts },
		getOrgs(state) { return state.orgs },
		getOrgInfo(state) { return state.org_info },
		getAppointmentOrgInfo(state) { return state.appointment_org_info },
		getSchedule(state) { return state.schedule },
		getScheduleTime(state) { return state.schedule_time },
	},
	mutations: {
		uploadOrgInfo(state) {
			state.org_info = {
				'logo': '',
				'name': '',
				'address': [],
				'phones': [],
				'site': '',
				'email': '',
			}
			var url = '/staff/orglist?short'
			http.get(url)
			.then(res=>{
				var obj = res.data
				//console.log(obj)
				state.org_info['address'] = Object.keys(obj).map((i)=>(obj[i]['address']))
				state.org_info['phones'] = obj[0]['phones']
				state.org_info['site'] = obj[0]['site']
				state.org_info['email'] = obj[0]['email']
				state.org_info['logo'] = obj[0]['logo']
				state.org_info['name'] = obj[0]['name']
			})
		},
		uploadOrgsWorkDays(state, org) {
			console.log('uploadOrgsWorkDays: Делаем запрос в базу для получения рабочих дней для организации с ID', org)
			var url = '/staff/schedules?org='+org
			var rs = http.get(url)
			.then(res=>{
				//console.log("RES:", res.data)
				state.schedule = res.data
			})
			console.log("uploadOrgsWorkDays:rs:", rs)
		},
		
		uploadOrgsWorkTimes(state, data) {
			// Получаем доступное для записи время
			console.log('store->user->uploadOrgsWorkTimes', data)
			var url = '/clients/appointments?org='+data.org+'&date='+data.date
			http.get(url)
			.then(res=>{
				state.schedule_time = res.data
				console.log("store->user->uploadOrgsWorkTimes:res.data:", res.data)
			})
			.catch(err=>{ console.log("store->user->uploadOrgsWorkTimes: ERROR:", err )} )
			console.log("store->user->uploadOrgsWorkTimes END", state.schedule_time)
			
		},
		setToken(state, payload) {
			console.log("store->user.js->setToken(): payload", payload)
			state.user  = payload.user
			state.token = payload.token
			state.refreshToken = payload.refreshToken
			localStorage.setItem('token', state.token)					// Записали access token
			localStorage.setItem('refreshToken', state.refreshToken)	// Записали refresh token
			http.defaults.headers.common['Authorization'] = state.token // Добавили заголовок
		},
		getRefreshToken(state, accessToken) {
			//state.status.loggedIn = true
			//state.user = { ...state.user, accessToken: accessToken }
			console.log('store->user.js->getRefreshToken state:', state)
			console.log('store->user.js->getRefreshToken accessToken:',  accessToken)
			console.log('store->user.js->getRefreshToken Получаем refreshToken из localStorage', localStorage.getItem("refreshToken"));
			//console.log("U:", localStorage)
			const user = JSON.parse(localStorage.getItem("user"));
			console.log("store->user.js->getRefreshToken Получили пользователя из localStorage и сохранили в user:", user)
			http.post("/lk/auth/refreshtoken", {refreshToken: localStorage.getItem("refreshToken"),})
			.then(res=>{
				console.log("store->user.js->getRefreshToken От сервера получили данные res:", res)
				console.log("store->user.js->getRefreshToken устанавливаем accessToken:", res.data.access_token)
				console.log("store->user.js->getRefreshToken устанавливаем refreshToken:", res.data.refresh_token)
				this.commit('setToken', {token:res.data.access_token, user:'chistov', refreshToken:res.data.refresh_token})
			})
			.catch(err=>{
				console.log("store->user.js->getRefreshToken Ошибка получения данных. Возвращена ошибка", err)
				console.log('store->user.js->getRefreshToken Удаляем access-token из localStorage')
				//this.commit('removeToken')
				// Редирект на страницу авторизации
				//return()
			})
		},
		removeToken(state) {
			console.log('store->user.js->removeToken: Удаляем токен')
			localStorage.removeItem('token')
			state.token = false
		},
		uploadDoctors(state, org_search='', doctor=1) {
			//var URL = '/staff/staffs/?org_search=Ветеринарная%20клиника%20"ВетЛайт"&doctor=1'
			console.log('org_search:', org_search, doctor)
			var URL = '/site/staff'
			http(URL)
			.then(res=>{
				state.doctors = res.data
			})
			.catch(err=>{this.commit('addErrors', "".concat("Upload doctors: ", err.message));console.error(err)})
			console.log('uploadDoctors', state.doctors)
			// DEBUG
			/*
			state.doctors = [
				{
					"staff_id": 1,
					"staff_users_full_name": "Чистов Максим Сергеевич",
					"staff_titul": "Руководитель отделения Травматологии и ортопедии, ветеринарный врач ВК \"ВетЛайт\"",
					"staff_description": "Врач хирург-ортопед",
					"staff_active": true,
					"staff_veterinar": true,
					"staff_photo": "http://lk.vetlight.ru/static/uploads/Doctor_Chistov.webp",
					"staff_orgs": [
						"Ветеринарная клиника \"ВетЛайт\" Новое Ступино",
						"Ветеринарная клиника \"ВетЛайт\" Домодедово"
					],
					"id": 1,
					"name": "Чистов Максим Сергеевич",
					"veterinar": true,
					"disabled": false
				},
				{
					"staff_id": 2,
					"staff_users_full_name": "Чистова Екатерина Сергеевна",
					"staff_titul": "Руководитель отделения терапии и диагностики, ветеринарный врач ВК \"ВетЛайт\"",
					"staff_description": "Главный ветеринарный врач",
					"staff_raiting": 5,
					"staff_active": true,
					"staff_veterinar": true,
					"staff_photo": "http://lk.vetlight.ru/static/uploads/Doctor_Chistova.webp",
					"staff_orgs": [
						"Ветеринарная клиника \"ВетЛайт\" Новое Ступино",
						"VetDigest",
						"Ветеринарная клиника \"ВетЛайт\" Домодедово"
					],
					"id": 2,
					"name": "Чистова Екатерина Сергеевна",
					"veterinar": true,
					"disabled": false
				}
			]
			*/
		},
		uploadStaffList(state) {
			var URL = '/staff/staffs/?fullstaff'
			http(URL)
			.then(res=>{
				//console.log('uploadStaffList')
				//console.log(res)
				state.staff_list = res.data
				state.staff_list_loading = false
			})
			.catch(err=>{ console.error(err) })
		},
		uploadContacts(state) {
			//var URL='/staff/orglist?search=Ветеринарная%20клиника%20"ВетЛайт"'
			var URL='/staff/orglist?search=Ветеринарная%20клиника'
			http.get(URL)
			.then(response=>{
				state.contacts = response.data
			})
		},
		uploadAppointmentOrgInfo(state){
			state.appointment_org_info = [
				{
					"org_name": "Ветеринарная клиника \"ВетЛайт\" Новое Ступино",
					"org_id": 1,
					"org_name_short": "ВК \"ВетЛайт\" Новое Ступино",
					"org_nname_exshort": "Новое Ступино",
					"org_coordinates": [ "55.024259","38.092961"],
					"org_email": "vetlight.clinic@gmail.com",
					"org_address": "142821, МО, Ступинский район, с.Верзилово, мкрн. Новое Ступино, ул.Олимпийская д.29, пом.43",
					"org_phone": "+7 (499) 713-07-77",
					"staff": [
						{
							"staff_id": 1,
							"name": "Чистов Максим Сергеевич",
							"staff_titul": "Руководитель отделения Травматологии и ортопедии, ветеринарный врач ВК \"ВетЛайт\"",
							"staff_description": "Врач хирург-ортопед",
							"staff_photo": "http://lk.vetlight.ru/static/uploads/Doctor_Chistov.webp",
							"workdays":['07.12.2021', '15.12.2021', '30.12.2021' ]
						},
						{
							"staff_id": 2,
							"name": "Чистова Екатерина Сергеевна",
							"staff_titul": "Руководитель отделения терапии и диагностики, ветеринарный врач ВК \"ВетЛайт\"",
							"staff_description": "Главный ветеринарный врач",
							"staff_photo": "http://lk.vetlight.ru/static/uploads/Doctor_Chistova.webp",
						}
					],
				},
				{
					"org_name": "Ветеринарная клиника \"ВетЛайт\" Домодедово",
					"org_id": 4,
					"org_name_short": "ВК \"ВетЛайт\" Домодедово",
					"org_nname_exshort": "Домодедово",
					"org_coordinates": [ "55.427699", "37.755463"],
					"org_email": "vetlight.clinic@gmail.com",
					"org_address": "МО, г.Домодеово, мкрн.Западный, ул.Лунная, д.5",
					"org_phone": "+7 (499) 713-07-77",
					"staff": [
						{
							"staff_id": 14,
							"name": "Курносова Маргарита",
							"staff_titul": "Руководитель отделения Травматологии и ортопедии, ветеринарный врач ВК \"ВетЛайт\"",
							"staff_description": "Врач хирург-ортопед",
							"staff_photo": "http://lk.vetlight.ru/static/uploads/Doctor_Chistov.webp",
							"workdays":['07.12.2021', '15.12.2021', '30.12.2021' ]
						},
						{
							"staff_id": 2,
							"name": "Чистова Екатерина Сергеевна",
							"staff_titul": "Руководитель отделения терапии и диагностики, ветеринарный врач ВК \"ВетЛайт\"",
							"staff_description": "Главный ветеринарный врач",
							"staff_photo": "http://lk.vetlight.ru/static/uploads/Doctor_Chistova.webp",
						}
					]
				}
			]
		},
		uploadOrgs(state) {
			var URL = '/staff/orglist'
			//var URL = '/staff/orglist?search=Ветеринарная%20клиника%20"ВетЛайт"'
			http.get(URL)
			.then(response=>{
				state.orgs = response.data
			})
			// Debug
			console.log('uploadOrgs')
			/* state.orgs =[
				{
					"org_name": "Ветеринарная клиника \"ВетЛайт\" Новое Ступино",
					"org_name_short": "ВК \"ВетЛайт\" Новое Ступино",
					"org_nname_exshort": "Новое Ступино",
					"org_coordinates": [
						"55.024259",
						"38.092961"
					],
					"org_email": "vetlight.clinic@gmail.com",
					"org_address": "142821, МО, Ступинский район, с.Верзилово, мкрн. Новое Ступино, ул.Олимпийская д.29, пом.43",
					"org_address_short": "ул.Олимпийская, д.29",
					"org_status": true,
					"org_phone": "+7 (499) 713-07-77",
					"org_business_hours_start": "10:00",
					"org_business_hours_stop": "20:00",
					"org_admission_interval": 30,
					"workdays":['07.12.2021', '15.12.2021', '30.12.2021' ]
				},
				{
					"org_name": "Ветеринарная клиника \"ВетЛайт\" Домодедово",
					"org_name_short": "ВК \"ВетЛайт\" Домодедово",
					"org_nname_exshort": "Домодедово",
					"org_coordinates": [
						"55.427699",
						"37.755463"
					],
					"org_email": "vetlight.clinic@gmail.com",
					"org_address": "МО, г.Домодеово, мкрн.Западный, ул.Лунная, д.5",
					"org_address_short": "ул.Лунная, д.5",
					"org_status": true,
					"org_phone": "+7 (499) 713-07-77",
					"org_business_hours_start": "09:00",
					"org_business_hours_stop": "21:00",
					"org_admission_interval": 30
				}
			]*/
		},
		auth_error(state){
			console.log('set status = error')
			state.status = 'error'
		},
	},
	actions: {
		getDoctorsAsync(context) { context.commit('uploadDoctors');  },
		getStaffListAsync(context) { context.commit('uploadStaffList')},
		uploadContactsAsync(context) { context.commit('uploadContacts')},
		refreshTokenAsync(context) { context.commit('getRefreshToken') },
		uploadOrgsAsync(context) { context.commit('uploadOrgs') },
		uploadOrgsWorkDaysAsync(context, org) { context.commit('uploadOrgsWorkDays', org)},
		uploadOrgsWorkTimesAsync(context, data) { context.commit('uploadOrgsWorkTimes', data)},
		uploadAppointmentOrgInfoAsync(context) { context.commit('uploadAppointmentOrgInfo')},
		uploadOrgInfoAsync(context) { context.commit('uploadOrgInfo')},

		async login(state, payload){
			//var URL = DOMAIN + '/api/v1/lk/login'
			console.log('dispatch->login')
			var URL = '/lk/login'
			var promise = new Promise((resolve, reject)=>{
				http({
					method: 'post',
					url: URL,
					data: {
						username: payload.username,
						password: payload.password,
					},
					//headers:{
					//	Authorization:'adasdfa',
					//}
				})
				console.log(resolve, reject)
			})
			console.log(promise)
			// .then((res)=>{
			// 	//state.commit('setToken', {token:res.data.refresh_token, user:'chistov'})
			// 	console.log('store.user.js.actions.login: res.data = ', res.data)
			// 	state.commit('setToken', {token:res.data.access_token, user:'chistov', refreshToken:res.data.refresh_token})
			// 	//state.commit('setRefreshToken', {token:res.data.refresh_token, user:'chistov'})
			// 	//resolve(res)
			// 	return true
			// })
			// .catch((err)=>{
			// 	state.commit('auth_error')
			// 	localStorage.removeItem('token')
			// 	throw(err)
			// })
		},
		logout(state){
			state.commit('removeToken')
		},
	},
}