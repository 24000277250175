<template>
	<div>
		<v-row>
			<v-col><H1>Статистика по клинике</H1></v-col>
		</v-row>
		<v-row>
			<v-col><v-select :items="clinics" v-model="clinic" label='Клиника' multiple></v-select></v-col>
		</v-row>
		<v-row>
			<v-col class="col-6"><FinanceChart :period="{year:'2022'}" :company="clinic"/></v-col>
		</v-row>
	</div>
</template>

<script type="text/javascript">
	
	import FinanceChart from "@/components/FinanceChart"
	export default {
		name: 'Finance',
		components: {
			FinanceChart,
		},
		data: ()=>({
			clinics: ['Новое Ступино', 'Домодедово'],
			clinic: ['Новое Ступино', 'Домодедово'],
		}),
		mounted() {},
		computed:{},
	}
</script>

<style type="text/css">
	
</style>