<template>
	<div>
		<!-- <meta http-equiv = "refresh" content = "1 ; url = http://lk.vetlight.ru"/> -->
		<!-- <meta http-equiv = "refresh" content = "1 ; url = http://lk.vetlight.ru"/> -->		
		<v-card>
			<v-navigation-drawer permanent expand-on-hover fixed class="mt-14 mt-md-16" app>
				<v-list>
					<v-list-item class="px-2">
						<v-list-item-avatar>
							<v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
						</v-list-item-avatar>
					</v-list-item>

					<v-list-item link>
						<v-list-item-content>
							<v-list-item-title class="title">Максим Чистов</v-list-item-title>
							<v-list-item-subtitle>vetlight.clinic@gmail.com</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-divider></v-divider>

				<v-list nav dense>
					<v-list-item link href="http://lk.vetlight.ru/partner/1/">
						<v-list-item-icon><v-icon>mdi-folder</v-icon></v-list-item-icon>
						<v-list-item-title>Админка</v-list-item-title>
					</v-list-item>
					<v-list-item link :to="{name:'Hospital'}">
						<v-list-item-icon><v-icon>mdi-cow</v-icon></v-list-item-icon>
						<v-list-item-title>Стационар</v-list-item-title>
					</v-list-item>
					<v-list-item link>
						<v-list-item-icon><v-icon>mdi-star</v-icon></v-list-item-icon>
						<v-list-item-title>Starred</v-list-item-title>
					</v-list-item>
					<v-list-item link :to="{name: 'Finance'}">
						<v-list-item-icon><v-icon>mdi-credit-card</v-icon></v-list-item-icon>
						<v-list-item-title>Финансы</v-list-item-title>
					</v-list-item>
					<v-list-item link :to="{name: 'Reception'}">
						<v-list-item-icon><v-icon>mdi-heart</v-icon></v-list-item-icon>
						<v-list-item-title>Приемы животных</v-list-item-title>
					</v-list-item>
					<v-list-item link>
						<v-list-item-icon><v-icon> mdi-biohazard</v-icon></v-list-item-icon>
						<v-list-item-title>Расходы</v-list-item-title>
					</v-list-item>
					<v-list-item link :to="{name: 'Management'}">
						<v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
						<v-list-item-title>Управление персоналом</v-list-item-title>
					</v-list-item>
					<v-list-item link v-on:click='exit'>
						<v-list-item-icon><v-icon>mdi-exit-run</v-icon></v-list-item-icon>
						<v-list-item-title>Выход</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>
		</v-card>

	</div>
</template>

<script type="text/javascript">

export default {
	name:'Staff',
	data () {
		return {
			test2:0
		}
	},
	computed: {
		test() {
			return false
		}
	},
	mounted () {
		this.$store.commit('setMenu', {title:'Тут будет новое меню'})
	},
	methods: {
		exit() { 
			this.$store.dispatch('logout') 
			this.$router.push('/')
		},
	}
}
</script>