<template>
	<div>
		<v-main>
			<v-container>
				<router-view></router-view>
			</v-container>
		</v-main>
	</div>
</template>

<script type="text/javascript">



export default {
	name: 'LayoutClear',
	components: {
	},
	data () {
		return {
			drawer: false,
		}
	},
}
</script>

