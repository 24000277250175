<template>
	<div>
		<template v-for="(error, index) in errors">
			<v-alert type="error" outlined dense dismissible :key="index">{{ errors[index] }}</v-alert>
		</template>
		<v-row>
			<template v-for="(cell, index) in cells">
				<v-col class="col-12 col-sm-4" :key="index">
					<v-card outlined :to="{name:'HospitalCell', params:{id: cell.id}}" :color="getInfo_by_id(cell.id, 'category', 'color')">
						<v-card-title>{{ cell.name }}</v-card-title>
						<v-card-subtitle>{{ getInfo_by_id(cell.id, 'category', 'name') }}</v-card-subtitle>
						<v-card-text>{{ getInfo_by_id(cell.id, 'animal', 'animal_name') }} </v-card-text>
						<v-card-actions>
							<v-btn color="black" text>Explore</v-btn>
							<v-spacer></v-spacer>
							<v-btn icon @click="show = !show">
								<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</template>
		</v-row>
	</div>
</template>

<script type="text/javascript">

export default {
	name: 'Hospital',
	data: ()=>({
		show: false,
		alert: true,
	}),
	methods: {
		getInfo_by_id(id, field, subfield=null) {
			//console.log(id)
			var result = this.hospital_list.filter((param)=>{ if (param.cell.id==id) return true })[0]
			var tmp_obj = null
			if (result) {
				if (field) {
					tmp_obj = result[field]
				}
				if (subfield) {
					tmp_obj = tmp_obj[subfield]
				}
			}
			return tmp_obj
		}
	},
	computed: {
		cells() { return this.$store.getters.getCells },
		hospital_list() { return this.$store.getters.getHospitalList },
		errors() { return this.$store.getters.getErrors},
	},
	beforeMount () {
		this.$store.dispatch('getCellsAsync')
		this.$store.dispatch('getHospitalListAsync')
	},
}
</script>