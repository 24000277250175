<template>
	<v-row class="ma-1">
		<v-col class="col-5 pa-1">
			<v-card height="50" dark class="primary d-flex align-center" href="tel:+74997130777">
				<div class="h1 flex-grow-1 text-center">Позвонить</div>
			</v-card>
		</v-col>
		<v-col class="col-2 pa-1">
			<v-menu top :close-on-content-click="closeOnContentClick">
				<template v-slot:activator="{ on, attrs }">
					<v-card height="50" dark class="primary d-flex align-center" v-bind="attrs" v-on="on">
						<div class="h1 flex-grow-1 text-center"><v-icon>mdi-map-marker</v-icon></div>
					</v-card>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in items" :key="index" :href="item.url">
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-col>
		<v-col class="col-5 pa-1">
			<v-card height="50" dark class="primary d-flex align-center" to="/appointment">
				<div class="h1 flex-grow-1 text-center">Запись онлайн</div>
			</v-card>
		</v-col>
	</v-row>
</template>

<script type="text/javascript">
export default {
	name: 'CallBack',
	data () {
		return {
			stocks: [],
			closeOnContentClick: true,
			items: [
				{ title: 'Домодедово',    url:'https://yandex.ru/maps/?mode=routes&rtext=~55.427794%2C37.755873&rtt=pd&ruri=~&z=17' },
				{ title: 'Новое Ступино', url:'https://yandex.ru/maps/?mode=routes&rtext=~55.024258%2C38.093042&rtt=pd&ruri=~&z=17' },
			],
		}
	},
}
</script>

<style type="text/css">
	
</style>