import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Doctors from '../views/Doctors'
//import Login from '@/components/Login'
import StaffMenu from '../views/StaffMenu.vue'
import Hospital from '../views/Hospital.vue'
import HospitalCell from '../views/HospitalCell.vue'
import Finance from '../views/Finance.vue'
//import Secure from '../components/Secure.vue'
import Reception from '../views/Reception.vue'
import Management from '../views/Management.vue'
import Anamnesis from '../views/Anamnesis.vue'



Vue.use(VueRouter)
import store from '../store/index.js'


const routes = [
	{ path: '',             name: 'Home', component: Home, },
	// Menu section
	{ path: '/price',       name:'Price',           component:()=>import('../views/Price.vue')},
	{ path: '/staff/',     	name:'Doctors',         component: Doctors},
	{ path: '/staff/:doctor_id',  name:'DoctorsID',         component: Doctors, props:true},
	{ path: '/documents',   name:'Documents',       component:()=>import('../views/Documents.vue')},
	{ path: '/articles',    name:'Articles',        component:()=>import('../views/Articles.vue')}, 
	{ path: '/recommendation', name:'Recomendation',component:()=>import('../views/Recomendation.vue')},
	{ path: '/stock',       name:'Stock',           component:()=>import('../views/Stock.vue')},
	{ path: '/news',        name:'News',            component:()=>import('../views/News.vue')},
	{ path: '/about',       name:'About',           component:()=>import(/* webpackChunkName: "about" */ '../views/About.vue') },
	{ path: '/doctors',		name:'ForDoctors',		component:()=>import('../views/ForDoctors.vue')},
	{ path: '/doctors/:post_id',name:'ForDoctors',	component:()=>import('../views/ForDoctors.vue'), props:true},


	{ path: '/contacts/',   name:'Contacts',        component:()=>import('../views/Contacts.vue')},
	{ path: '/appointment', name:'Appointment',     component:()=>import('../views/Appointment_v3.vue')},
	{ path: '/appointment_staff', name:'Appointment',     component:()=>import('../views/Appointment.vue')},
	
	// View post
	{ path: '/post/:post_id',name:'Post',            component:()=>import('../views/Post.vue'), props:true},


	
	
	{ path: '/inventory/',   name:'Inventory',       component:()=>import('../views/Inventory.vue'), meta:{layout:'clear-layout'}},
	{ path: '/login',        name:'Login',           component:()=>import('@/components/Login'), meta:{layout:'clear-layout'}},


	// View Anamnesis
	{ path: '/llk/anamnesis', name:'Anamnesis',	component: Anamnesis},

	{ path: '/llk',                   name:'Staff',         meta: {requiresAuth:true}, components: { staff: StaffMenu, default: Hospital }},
	{ path: '/llk/hospital',          name:'Hospital',      meta: {requiresAuth:true}, components: { staff: StaffMenu, default: Hospital }},
	{ path: '/llk/hospital/cell/:id', name:'HospitalCell',  meta: {requiresAuth:true}, components: { staff: StaffMenu, default: HospitalCell }},
	{ path: '/llk/finance',           name:'Finance',       meta: {requiresAuth:true}, components: { staff: StaffMenu, default: Finance }},
	{ path: '/llk/reception',         name:'Reception',     meta: {requiresAuth:true}, components: { staff: StaffMenu, default: Reception }},
	{ path: '/llk/management',        name:'Management',    meta: {requiresAuth:true}, components: { staff: StaffMenu, default: Management }},

	{ path: '/lk/',											name:'Lk', component:()=>import('../views/LK.vue')},
	{ path: '/lk/result',								name:'Result', component:()=>import('../views/Result.vue')},
	//{ path: '*', name: '404', component: Home,}  // Маршрут для всех 404
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAuth)) {
		// Проверка авторизован пользователь или нет. Устанавливается черзе тег meta->requiresAuth
		if (store.getters.isLoggedIn) {
			console.log('Router.index.js: Пользователь уже авторизован')
			next()
			return
		}
		console.log('Router.index.js: Не залогинен -> на авторизацию')
		console.log('Router.index.js: to:', to)
		var redirect_url = '/login?path='+to.path;
		next(redirect_url) 
	} else {
		console.log('Router.index.js: Не требуется авторизация (from)', from, "(to)", to)
		console.log('Router.index.js: from.query.path:', from.query.path)
		next(from.query.path)
	}
})

export default router
