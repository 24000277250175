<template>
	<v-row justify="center">
		asfsa
		<v-dialog v-model="dialog" persistent max-width="600px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" dark v-bind="attrs" v-on="on">Open Dialog</v-btn>
			</template>

			<v-card>
				<v-card-title>
					<span class="headline">Анамнез</span>
				</v-card-title>
				<v-card-text>
					{{ result }}
					<v-container>
						<v-expansion-panels>
							<v-expansion-panel v-for="(val, key) in anamnesis" :key='key'>
								<v-expansion-panel-header>{{ key }}</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div v-if="typeof(val[0])==typeof('')">
										<v-chip-group multiple column v-model="result[key]">
											<v-chip v-for='(v,i) in val' :key="i" :value="v" filter >{{ v }}</v-chip>
										</v-chip-group>
									</div>
									<div v-else >
										<v-card v-for='(subval, subkey) in val' :key="subkey">
											<v-card-title>{{ subkey }}</v-card-title>
											<v-card-text>
												<v-chip-group multiple column v-model="result[key][subkey]">
													<v-chip v-for='sub_v in subval' :key="sub_v" :value="sub_v" filter>{{ sub_v }}</v-chip>
												</v-chip-group>
											</v-card-text>
										</v-card>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>

						
					</v-container>
					<small>*indicates required field</small>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
					<v-btn color="blue darken-1" text @click="dialog = false">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>


<script type="text/javascript">
export default {
	data: () => ({
		dialog: true,
		test: [],
		params:[
		],
		result:{
			'Кожа': ['Сухая', 'Шелушение'],
			'Глаза': {
				'Левый глаз': ['Выделения', 'Инфекция'],
				'Правый глаз':['Норма'],
			},
			'Уши':{},
			'Скелетно-мышечный аппарат':{},
			'Сердце':{},
		},
		anamnesis: {
			'Кожа': ['Норма', 'Сухая', 'Тусклая', 'Жирная', 'Шелушение', 'Пигментация', 'Линька', 'Поражения', 'Колтуны'],
			'Глаза':{
				'Левый глаз': ['Норма', 'Выделения', 'Воспаление', 'Инфекция', 'Катаракта', 'Травма', 'Язвы', 'Сухой глаз'],
				'Правый глаз':['Норма', 'Выделения', 'Воспаление', 'Инфекция', 'Катаракта', 'Травма', 'Язвы', 'Сухой глаз'],
			},
			'Уши': {
				'Левое ухо': ['Норма', 'Выделения', 'Воспаление', 'Инфекция', 'Травма', 'Язвы', 'Зуд', 'Клещи',],
				'Правое ухо':['Норма', 'Выделения', 'Воспаление', 'Инфекция', 'Травма', 'Язвы', 'Зуд', 'Клещи',],
			},
			'Носоглотка': ['Норма', 'Выделения', 'Воспаление', 'Инфекция', 'Миндалины', 'Гланды',],
			'Ротовая полость': ['Норма', 'Сломанный зуб', 'Постоянные зубы', 'Гингевит', 'Парадонтит/Парадантоз', 'Язвы', 'Поражения слизистой',],
			'Скелетно-мышечный аппарат':{
				'Общее': ['Без изменений', 'Хромота', 'Скованность движений', 'Ограничения'],
				'1 Плечевая кость': [],
				'2 Локтевая кость': [],
				'3 Бедро': [],
				'4 Голень': [],
				'5 Позвоночник': [],
				'6 Тазовые кости': [],
				'7 Пальцы грудных конечностей': [],
				'8 Пальцы тазовых конечностей': [],
				'9 Череп': [],
			},
			'Сердце': {
				'Тоны сердца': [],
				'Ритм': ['Норма', 'Аритмия',],
				'Шумы': ['Отсутствуют', 'Присутствуют',],
			},
			'Абдомен': ['Норма', 'Увеличение органов', 'Жидкость', 'Мягкий', 'Жесткий', 'Острый живот', 'Болезнянный', 'Новообразование',],
			'Легкие': ['Везикульярное дыхание (норма)', 'Шумы', 'Кашель', 'Крипитация', 'Затрудненное дыхание', 'Острый респираторный дистрес синдром',],
			'Желудочно-кишечный тракт': ['Норма', 'Газообразование', 'Рвота', 'Анорексия', 'Кахексия', 'Жидкий стул', 'Запор', 'Паразитарные инфекции', ],
			'Мочевыделительная и репродуктивная система': ['Норма', 'Неадекватное мочеиспускание', 'Воспаление урогенитального тракта', 'Ненормативные тестисы', 'Рекомендована кастрация', 'Опухоли молочной железы', 'Анальные патологии', 'Увеличена простата',],
			'Вес': ['Норма', 'Ожирение', 'Кахексия', 'Другое', ],
			'Диета': ['Промышленный корм', 'Промышленный корм + домашняя', 'Только домашняя', 'Витамины и добавки', 'Корм эконом класса', 'Корм премиум класса',],
			'Тесты на инфекции': ['Не проводились', 'Отрицательно', 'Положительно',],
			'Тесты на дирофиляриоз': ['Не проводились', 'Отрицательно', 'Положительно',],
			'Обработка от паразитов': ['Нет информации', 'Ежемесячно', 'Периодически'],
			'Вакцинация': ['Ежегодно комплекс', 'Ежегодно бешенство', 'Не проводится',],
		},
	}),
	methods: {
		append(key, subkey, item) {
			console.log('append', key, subkey, item)
			/*if (subkey == '') {
				this.result[key].push(item)
			} else {
				this.result[key][subkey].push(item)
			}
			*/
		}
	},

}

</script>

<style scope>
	
</style>