//import http from '../store/http' 


export default {
	state: {
		adminMenu:'Menu',
		count: 0,
	},
	getters: {
		getMenu: state => {
			//console.log('getMenu')
			//console.log(state.adminMenu)
			return state.adminMenu
		}
	},
	mutations: {
		increment(state, payload){
			state.count+= payload.amount;
		},
		setMenu(state, payload){
			//console.log("setMenu")
			//console.log(payload.title)
			state.adminMenu = payload.title
		},
	},
	actions: {
	},
}