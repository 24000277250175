<template>
	<v-col class="col-12">
		<v-card color='grey lighten-5'>
			<v-card-title class="text-center light-blue--text text--darken-2 text-h4">НАШИ УСЛУГИ</v-card-title>
			<v-card-text>
				<v-row>
					<v-col class="col-md-4 col-sm-6 col-12" v-for="service in services" :key="service.title">
						<v-icon large color="light-blue darken-2" >{{ service.icon }}</v-icon>
						{{ service.title }}
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
	</v-col>

</template>


<script>

export default {
	name: 'Services',
	computed: {
		services() { return this.$store.getters.getServiceList }
	},
	beforeMount() {
		this.$store.dispatch('uploadServiceListAsync')
	}
}
</script>

