//import Axios from 'axios'
//const DOMAIN = 'http://lk.vetlight.ru:65500'
import http from '../store/http' 


export default {
	state: {
		hospital_info: {},
		categorys: [],
		cells: [],
		hospital_list: [],
		hospital_records: [],
	},
	getters: {
		getHospital(state)	      { return state.hospital_info },
		getCategorys(state)	      { return state.categorys },
		getCells(state) 	      { return state.cells },
		getHospitalList(state)    { return state.hospital_list },
		getHospitalRecords(state) { return state.hospital_records },
	},
	mutations: {
		uploadCategorys(state) {
			//var URL = DOMAIN + '/api/v1/hospital/category/'
			var URL = '/hospital/category/'
			http.get(URL)
			.then(res=>{
				//console.log(res)
				state.categorys = res.data
			})
			.catch(err=>{this.commit('addErrors', "".concat("Category: ", err.message));console.error(err)})
		},	
		uploadCells(state) {
			//var URL = DOMAIN + '/api/v1/hospital/cells/'
			var URL = '/hospital/cells/'
			http.get(URL)
			.then(res=>{
				//console.log(res)
				state.cells = res.data
			})
			.catch(err=>{this.commit('addErrors', "".concat("Cells: ", err.message));console.error(err)})
		},
		uploadHospitalInfo(state, id){
			// Получаем данные по стационарному приему
			//var URL = DOMAIN + '/api/v1/hospital/hospital/?id='+id
			var URL = '/hospital/hospital/?id='+id
			//console.log(URL)
			http.get(URL)
			.then(res=>{
				console.log(res.data)
				this.hospital_info = res.data[0]
			})
			.catch(err=>{this.commit('addErrors', "".concat("Hospital id: ", err.message));console.error(err)})
		},
		uploadHospitalList(state) {
			// Получаем список животных на стационаре
			//var URL = DOMAIN + '/api/v1/hospital/hospital/'
			var URL = '/hospital/hospital/'
			console.log('uploadHospitalList: '+ URL)
			http.get(URL)
			.then(res=>{
				console.log(res.data)
				state.hospital_list = res.data
			})
			.catch(err=>{this.commit('addErrors', "".concat("Hospital: ", err.message));console.error(err)})
		},
		uploadHospitalRecords(state, id) {
			//var URL = DOMAIN + '/api/v1/hospital/hospitalrec/'
			var URL = '/hospital/hospitalrec/'
			console.log('ID:' + id)
			if (id) {
				URL = URL + '?id='+id
			}
			console.log('API_HospitalRecords ' + URL)
			http.get(URL)
			.then(res=>{
				console.log(res)
			})
			.catch(err=>{this.commit('addErrors', "".concat("HospitalRec: ", err.message));console.error(err)})
		},
	},
	actions: {
		getCategorysAsync(context) 		    { context.commit('uploadCategorys') },
		getCellsAsync(context) 			    { context.commit('uploadCells') },
		getHospitalListAsync(context)	    { context.commit('uploadHospitalList')},
		getHospitalRecordsAsync(context, id){ context.commit('uploadHospitalRecords', id)},
	},
}

