<template>
	<div>
    <v-row><USP></USP></v-row>
    <v-row><CallBack></CallBack></v-row>

		<v-row><Stock></Stock></v-row>
		<v-row><Services></Services></v-row>

    <v-row class="d-sm-none"><CallBack></CallBack></v-row>
		<v-row><About></About></v-row>

    <v-row><CallBack></CallBack></v-row>
		<v-row><Contacts></Contacts></v-row>
	</div>

</template>

<script>
// @ is an alias to /src
import USP from '@/components/USP'
import CallBack from '@/components/CallBack'
import Stock from '@/components/Stocks'
import Services from '@/components/Services'
import About from '@/components/About'
import Contacts from '@/components/Contacts'

export default {
  name: 'Home',
  components: {
    USP,
    CallBack,
    Services,
    Stock,
    About,
    Contacts,
  },
}
</script>
