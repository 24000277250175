<template>
	<v-app id="inspire">
		<v-system-bar app class="secondary">
			<v-spacer></v-spacer>
			<v-icon>mdi-square</v-icon>
			<v-icon>mdi-circle</v-icon>
			<v-icon>mdi-triangle</v-icon>
		</v-system-bar>

		<v-app-bar app clipped-right flat height="72" color="primary" dark class="ml-1">
			<v-col>Ветеринарная клиника</v-col>
			<v-spacer></v-spacer>
			<v-responsive max-width="156">
				<v-text-field dense flat hide-details rounded solo-inverted></v-text-field>
			</v-responsive>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" app width="300">
			<v-navigation-drawer v-model="drawer" absolute color="secondary" mini-variant>
				<v-avatar class="d-block text-center mx-auto mt-4" color="red" size="50"></v-avatar>
				<v-divider class="mx-3 my-5"></v-divider>
				<v-list>
					<v-list-item v-for="n in menus" :key="n.title" :to="n.route" class="pt-5 pl-2">
						<v-avatar  class="d-block text-center mx-auto mb-9" color="grey lighten-1" size="28"><v-icon>{{ n.icon }} {{ n.router }}</v-icon></v-avatar>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>

			<v-sheet color="secondary2" height="128" width="100%">
				<v-row>
					<v-col class="col-12 text-center primary">
						<v-avatar color="transparent" size="100" class="mr-2"><img src="/img/logo.png" alt=""></v-avatar>
					</v-col>
				</v-row>
			</v-sheet>

			<v-list class="pl-14" shaped>
				<v-list-item v-for="n in 5" :key="n" link>
					<v-list-item-content>
						<v-list-item-title>ItemLeft {{ n }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>

		<v-navigation-drawer app clipped right>
			<v-list>
				<v-list-item v-for="n in 5" :key="n" link>
					<v-list-item-content>
						<v-list-item-title>ItemRight {{ n }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>

		<v-main>
			<v-container>
				Menu - {{ getLeftMenu }}
				<router-view></router-view>
			</v-container>
		</v-main>

		<v-footer app color="transparent" height="72" inset>
			<v-text-field background-color="grey lighten-1" dense flat hide-details rounded solo></v-text-field>
		</v-footer>
	</v-app>
</template>

<script type="text/javascript">



export default {
	name: 'AdminLayout',
	components: {
	},
	data () {
		return {
			drawer: true,
			leftMenu: '-',
		}
	},
	watch: {
		leftMenu () {
			console.log('watch')
		}
	},
	methods: {

	},
	computed:{
		getLeftMenu(){
			return (this.$store.getters.getMenu)
		},
		menus() {
			return [
				{title:'Главная', route:'/', icon:'mdi-home'},
				{title:'Запись на прием', route:'/appointment', icon:'mdi-clipboard-edit-outline'},
				{title:"Кабинет", route:'/staff/', icon:'mdi-account-outline'},
			]
		}
	},
	beforeMount() {
		//this.getLeftMenu()
		this.getLeftMenu
	}
}
</script>

