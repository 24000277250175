<template>
	<v-col class="col-12">
		<v-card color='grey lighten-5'>
			<v-card-title class="text-center light-blue--text text--darken-2 text-h4">О НАС</v-card-title>
			<v-card-text>
				<v-row>
					<v-col class="col-12" v-html="about">
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
	</v-col>

</template>


<script>
export default {
	name: 'About',
	computed: {
		about() { return this.$store.getters.getAbout },
	},
	beforeMount(){
		this.$store.dispatch('uploadAboutAsync')
	},
}
</script>

