import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#0288d1',
				secondary: colors.grey.lighten3,
				secondary2: colors.grey.lighten5,
				accent: colors.shades.black,
				error: colors.red.accent3,
			},
			dark: {
				primary: colors.blue.lighten3,
			},
		},
	},
	/*icons: {
		iconfont: 'mdiSvg',
	}*/
});

