<template>
	<div>
		Z-Отчеты<br>
		<!--Z:{{ zreport.data[0] }}<br>-->
		<!--{{ zreport }}-->
		P:{{ period }}<br>
		C:{{ company }}<br>

		<line-chart :styles="chartStyle" :chart-data="datacollection" :options="chartOptions"></line-chart>
	</div>
</template>

<script type="text/javascript">
import Vue from 'vue'
//import { Bar, Line, Radar, mixins } from 'vue-chartjs'
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins


Vue.component('line-chart', {
	extends: Line,
	mixins: [reactiveProp],
	props: ['chartData', 'options'],
	mounted () { this.renderChart(this.chartData, this.options) },
})

//const data = [{x: 'Jan', net: 100, cogs: 50, gm: 50}, {x: 'Feb', net: 120, cogs: 55, gm: 75}];
/*data= [
	{day: "2017-04-18", downloads: 16280},
	{day: "2017-04-19", downloads: 14280},
	{day: "2017-04-20", downloads: 17280}
]*/

export default {
	name: 'Finance',
	props: ['period', 'company',],
	components: {},
	data: ()=>({
		chartOptions: {
			//responsive: true, 
			//maintainAspectRatio: false,
			legend: {
				display: false // отключает легенду, бесполезную когда на графике одна линия
			},
			scales: {
				yAxes: [{
					display: true,
					ticks: {
						precision: 0.1, // точность округления значений по оси y
						maxTicksLimit: 100 // максимальное количество значений (и рёбер сетки) по оси y
					}
				}],
				xAxes: [{
					display: true,
					ticks: {
						maxTicksLimit: 12 // максимальное количество значений (и рёбер сетки) по оси x
					}
				}]
			}
		},
		
	}),
	mounted() {	},
	methods:{
		getZreport(){
			if (typeof(this.$store.getters.getZReports.data)==(typeof Object())){
				console.log("1: total_checks")
				var result = []
				var rec = this.$store.getters.getZReports.data.filter(item=>item.org=='Ветеринарная клиника "ВетЛайт" Новое Ступино')
				rec.forEach(function(el) { result.push(el.total_checks)})
				//rec.forEach(el=> console.log(el))
				//console.log("V:", result)
				return result
			}
		},
	},
	computed:{
		chartStyle(){ return { height: '200px',} },
		datacollection(){
			var result_ns = []
			var result_dmd = []
			var result_labels = []
			if (typeof(this.$store.getters.getZReports.data)==(typeof Object())){
				console.log("1: total_checks", this.$store.getters.getZReports)
				var rec_ns = this.$store.getters.getZReports.data.filter(item=>item.org=='Ветеринарная клиника "ВетЛайт" Новое Ступино')
				var rec_dmd = this.$store.getters.getZReports.data.filter(item=>item.org=='Ветеринарная клиника "ВетЛайт" Домодедово')
				rec_ns.forEach(function(el) { result_ns.push(el.total_checks)})
				rec_dmd.forEach(function(el) { 
					//console.log(el)
					result_dmd.push(el.total_checks)
				})
				var rec_labels = this.$store.getters.getZReports.data.filter(item=>item.org=='Ветеринарная клиника "ВетЛайт" Новое Ступино')
				rec_labels.forEach(function(el) { result_labels.push(el.date)})
				//rec.forEach(el=> console.log(el))
				//console.log("V:", result)
			} else {
				console.log("2")
			}

			var result = {
				//labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
				labels: result_labels,
				datasets: [
					{
						label: 'Новое Ступино',
						//backgroundColor: '#f87979',
						borderColor: 'green',
						//data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
						data: result_ns,
						fill: false,
						pointRadius: 3,
					},
					{
						label: 'Домодедово',
						//backgroundColor: '#f87979',
						borderColor: '#FF00FF',
						//data: [15, 22, 10, 59, 1, 30, 59, 30, 20, 40, 19, 41],
						data: result_dmd,
						fill:false,
					},
				],
			}
			console.log(result)
			return result
		},
		zreport() { 
			if (typeof(this.$store.getters.getZReports.data)==(typeof Object())){
				console.log("1: total_checks")
				var result = []
				var rec = this.$store.getters.getZReports.data.filter(item=>item.org=='Ветеринарная клиника "ВетЛайт" Новое Ступино')
				rec.forEach(function(el) { result.push(el.total_checks)})
				//rec.forEach(el=> console.log(el))
				//console.log("V:", result)
				return result
			} else {
				console.log("2")
			}
			
			return this.$store.getters.getZReports 
		},
	},
	beforeMount() {
		//console.log('moment:', moment)
		this.$store.dispatch('uploadZReportsAsync')
	},
}

</script>