import Axios from 'axios'
//const URL = 'http://lk.kondorvet.ru:65500/api/v1'
const URL = 'http://lk.vetlight.ru/api/v1'


export default Axios.create({
	baseURL: URL,
	headers: {
		'Content-Type': "application/json",
	}
});
